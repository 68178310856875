import React, { useContext, useEffect } from "react"
import img_pack from "../img/img";
import { Link, useLocation } from "react-router-dom";
import Map2gis from "./components/Map2gis";
import { Context } from "../App";

const Plug = () => {

    const {windowWidth} = useContext(Context)
    const location = useLocation()

    return (
        <>
            <div style={{display:'flex', width: '100%', justifyContent: 'center', padding: '10px', borderBottom: '1px solid #00000033'}}>
                <img src={`${img_pack.assauto_logo}`} alt="logo" />
            </div>
            <div className="Plug" style={{minHeight: 'calc(100vh - 82px)'}}>
                <div className="container">
                    <div className="col">
                        <div className="h-26-400-j c-2 block-base-content">
                            Сайт находится в разработке!
                        </div>
                        <div className="contacts-block">
                            <div className="contact-info">
                                <div className="h-26-400-j c-2">Контакты</div>
                                <div className="contacts-row">
                                    <div className="contact-ex">
                                        <a href="tel:+73517764991" className="brand h-24-600-i">+7 (351) 776-49-91</a>
                                        <span className="h-16-400-i">пн-пт: 9:00-18:00, сб-вс: выходной</span>
                                        {/* <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn h-14-400-j">Заказать звонок</Link> */}
                                    </div>
                                    <div className="contact-ex">
                                        <a href="mailto:tkass74@internet.ru" className="brand h-24-600-i">tkass74@internet.ru</a>
                                        <span className="h-16-400-i gr5">Напишите нам</span>
                                    </div>
                                    <div className="row-15">
                                        <Link to={'https://wa.me/79511134816'}><img src={`${img_pack.contact_whatsappIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                        <Link to={'https://t.me/TKACC74'}><img src={`${img_pack.contact_telegramIcon}`} alt="ic" style={{minWidth: '32px', maxWidth: '32px'}}/></Link>
                                    </div>
                                </div>
                                <div className="contact-ex">
                                    <div className="row-15-a">
                                        <img src={`${img_pack.contact_mappinIcon}`} alt="ic" />
                                        <span className="brand h-16-400-i">Офис</span>
                                    </div>
                                    <span className="h-16-400-i">Челябинск, ​Академика Макеева, 36, офис 25</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Map2gis></Map2gis>
            </div>
        </>
    )
}

export default Plug;