import { useEffect, useRef, useState } from "react";
import { useFetching } from "./UseFetching";
import GetConfigurator from "../API/GetConfigurator";
import GetMain from "../API/GetMain";

export const useByParamsConfigurator = ({selected1}) => {

    
    const [dataByParamTires, setDataByParamTires] = useState({
        width: [],
        profile: [],
        diameter: [],
        seasonality: [],
        type: [],
        manufacturer: [],
    })
    const [dataByParamDiscs, setDataByParamDiscs] = useState({
        width: [],
        diameter: [],
        pcd: [],
        et: [],
        hub: [],
        manufacturer: [],
    })

    const [allTiresWidth, setAllTiresWidth] = useState([])
    const [allTiresProfile, setAllTiresProfile] = useState([])
    const [allTiresDiameter, setAllTiresDiameter] = useState([])
    const [allTiresSeasonality, setAllTiresSeasonality] = useState([])
    const [allTiresType, setAllTiresType] = useState([])
    const [allTiresManufacturer, setAllTiresManufacturer] = useState([])
    
    const [allDisksWidth, setAllDisksWidth] = useState([])
    const [allDisksDiameter, setAllDisksDiameter] = useState([])    
    const [allDisksPCD, setAllDisksPCD] = useState([])
    const [allDisksET, setAllDisksET] = useState([])
    const [allDisksHub, setAllDisksHub] = useState([])
    const [allDisksManufacturer, setAllDisksManufacturer] = useState([])

    const [allSubcategoryAttrs, setAllSubcategoryAttrs] = useState([]) 
    const [selectedSubcategoryAttrs, setSelectedSubcategoryAttrs] = useState([]) 

    // Получить подкатегории для параметров
    const [GetSubcategoryAttrs] = useFetching( async () => {
        const formdata = new FormData();
        formdata.append("category", selected1 ? 'DISKS' : 'TIRES');
        const res = await GetConfigurator.GetSubcategoryAttrs(formdata)
        // console.log('GetSubcategoryAttrs complete:' , res.data)
        setAllSubcategoryAttrs(res.data.unique_subcategories?.map((el,i) => ({id:i, name: el.subcategory__name, slug: el.subcategory__slug})))
    })
    // Получить атрибуты для шин
    const [GetConfiguratorTireAttr,tiresAttrLoading] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorTireAttr(data)
        // console.log('GetConfiguratorTireAttr complete:' , res.data)
        setAllTiresWidth(res.data?.attribute_values?.width?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllTiresProfile(res.data?.attribute_values?.height?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllTiresDiameter(res.data?.attribute_values?.diameter?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllTiresSeasonality(res.data?.attribute_values?.season?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllTiresType(res.data?.attribute_values?.thorn?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllTiresManufacturer(res.data?.attribute_values?.brands?.map((el) => ({id: el.id, slug: el.slug, name: el.name})) )
    })
    // Получить атрибуты для диска
    const [GetConfiguratorDiskAttr,disksAttrLoading] = useFetching( async (data) => {
        const res = await GetConfigurator.GetConfiguratorDiskAttr(data)
        // console.log('GetConfiguratorDiskAttr complete:' , res.data)
        setAllDisksWidth(res.data?.attribute_values?.width?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllDisksDiameter(res.data?.attribute_values?.diameter?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllDisksPCD(res.data?.attribute_values?.pcd?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllDisksET(res.data?.attribute_values?.et?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllDisksHub(res.data?.attribute_values?.hub?.map((el,i) => ({id: i, slug: el, name: el})))
        setAllDisksManufacturer(res.data?.attribute_values?.brands?.map((el) => ({id: el.id, slug: el.slug, name: el.name})))
    })

    const prevSelectedSubcategoryAttrsRef = useRef(selectedSubcategoryAttrs);
    useEffect(() => {
        // console.log('selectedSubcategoryAttrs',selectedSubcategoryAttrs)
        if (prevSelectedSubcategoryAttrsRef.current !== selectedSubcategoryAttrs) {
            // console.log('reset')
            setDataByParamTires({width: [],profile: [], diameter: [], seasonality: [], type: [], manufacturer: [],});
            setDataByParamDiscs({ width: [], diameter: [], pcd: [], et: [], hub: [], manufacturer: [],});
            prevSelectedSubcategoryAttrsRef.current = selectedSubcategoryAttrs;
        }
        if(selectedSubcategoryAttrs.length > 0 && selectedSubcategoryAttrs[0]?.value) {
            const data = new FormData();
            data.append("subcategory_slug", selectedSubcategoryAttrs[0]?.value);
            if(selected1) { // DISKS
                GetConfiguratorDiskAttr(data)
            } else {  //TIRES
                GetConfiguratorTireAttr(data)
            }
        }
    },[selectedSubcategoryAttrs])

    useEffect(() => {
        if(allSubcategoryAttrs !== 0 && selectedSubcategoryAttrs.length !== 0 ) {
            setAllSubcategoryAttrs([])
            setSelectedSubcategoryAttrs([])
        }
        if(selected1) { // DISKS
            setSelectedSubcategoryAttrs([{value: 'disk_car', name: 'Легковой диск'}])
        } else {  //TIRES
            setSelectedSubcategoryAttrs([{value: 'car', name: 'Легковая шина'}])
        }
        GetSubcategoryAttrs()
    },[selected1])
    
    return {prevSelectedSubcategoryAttrsRef,tiresAttrLoading,disksAttrLoading,allSubcategoryAttrs,selectedSubcategoryAttrs,setSelectedSubcategoryAttrs,allTiresWidth,allTiresProfile,allTiresDiameter,allTiresSeasonality,allTiresType,allTiresManufacturer,allDisksWidth,allDisksDiameter,allDisksPCD,allDisksET,allDisksHub,allDisksManufacturer,dataByParamTires, setDataByParamTires, dataByParamDiscs, setDataByParamDiscs}
}