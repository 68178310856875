import React, { createContext, useEffect, useRef, useState } from "react"
import { Navigate, Route, Routes, useLocation } from "react-router-dom"
import Layout from "./Pages/Layout"
import Main from "./Pages/Main"
import { adminRoutes, otherRoutes, publicRoutes } from "./utils/routes"
import AdminLayout from "./Pages/AdminLayout"
import AdminGeneralPage from "./Pages/AdminGeneralPage"
import ItemPo from "./Pages/components/Item_po"
import Popup from "./Pages/components/Popup"
import MediafilesPo from "./Pages/components/MediafilesPo"
import { ru } from "date-fns/locale"
import { registerLocale, setDefaultLocale } from "react-datepicker"
import { ToastContainer } from 'react-toastify';
import { Page404_Root, ScrollToTop } from "./utils/consts"
import ContactPo from "./Pages/components/Contact_po"
import Plug from "./Pages/Plug"
import GetCart from "./API/GetCart"
import { useFetching } from "./hooks/UseFetching"
import { useCart } from "./hooks/UseCart"
import { UseCatsBrandSidebar } from "./hooks/UseCatsBrandSidebar"

export const Context = createContext(null)

registerLocale('ru', ru)
setDefaultLocale('ru');

const App = () => {

  const location = useLocation()
  const prevLocation = location.state?.prevLocation

  // resize

  const { allBrandsSide, allCatsSide, GetStoreCatsSidebar, GetStoreBrandSidebar } = UseCatsBrandSidebar();
  const { cart, setCart, productsAll, setProductsAll, ShowCart, UpdateCart, updateQuantity, AddCart, DeleteFromCart } = useCart();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [breadCrumbs, setBreadCrumbs] = useState([])
  const [curModal, setCurModal] = useState(false)

  useEffect(() => {
    const handleResize = () => {
    setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Убираем слушатель события при размонтировании компонента
    return () => {
    window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    ShowCart()
  },[])

  return (
    <Context.Provider
      value={{windowWidth,
        ShowCart, UpdateCart,DeleteFromCart, updateQuantity, AddCart,
        cart, setCart,
        productsAll, setProductsAll,
        allBrandsSide, allCatsSide, GetStoreCatsSidebar, GetStoreBrandSidebar,
        breadCrumbs, setBreadCrumbs,
        curModal, setCurModal,
      }}
    >
      <ScrollToTop />
      <div>
        <Routes location={prevLocation || location}>

          <Route path="admin" element={<AdminLayout />}>
            <Route index element={<AdminGeneralPage />}/>
            {adminRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
          </Route>

          <Route path="/" element={<Layout />}>
            <Route index element={<Main />}/>
            {publicRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            {otherRoutes.map(({path, element}) => 
              <Route key={path} path={path} element={element}/>
            )}
            <Route path="*" element={ <Navigate to={`${Page404_Root}`} />}/> 
          </Route>

        </Routes>
        {prevLocation && (
        <Routes>
          <Route path="contact_po" element={<Popup><ContactPo /></Popup>} />
          <Route path="item_po" element={<Popup><ItemPo /></Popup>} />
          <Route path="mediafiles_po" element={<Popup><MediafilesPo /></Popup>} />
        </Routes>
        )}
      </div>
      <ToastContainer />
    </Context.Provider>
  )
}

export default App;
