import React, { useEffect, useRef, useState } from "react"
import img_pack from "../img/img"
import { useLocation, useNavigate } from "react-router";
import GetStore from "../API/GetStore";
import { useFetching } from "../hooks/UseFetching";
import ToggleBtn from "./components/toggleBtn";
import { format } from "date-fns";

const ImportTabs = () => {

    const [selectedBtn, setSelectedBtn] = useState('1')
    const [ImportTabs, setImportTabs] = useState({
        "file_id": "",
        "slug": "",
        "is_work": '',
        "extra_charge": '',
        "tire_last_status": "", 
        "disk_last_status": "",
        "tire_last_run_time": "",
        "disk_last_run_time": "",
    })

    const location = useLocation()
    const navigate = useNavigate()

    function menuBtnClick(e) {
        const numb = e.currentTarget.getAttribute('numb')
        if(selectedBtn !== numb) {
            setSelectedBtn(numb)
        }
    }

    const [GetImport] = useFetching( async (data) => {
        const res = await GetStore.GetImport(data)
        // console.log('GetImport complete:' , res.data)
        setImportTabs(prevState => ({
            ...prevState,
            file_id: res.data?.supplier_data?.file_id || prevState.file_id,
            slug: res.data?.supplier_data?.slug || prevState.slug,
            is_work: res.data?.supplier_data?.is_work || prevState.is_work,
            extra_charge: res.data?.supplier_data?.extra_charge || prevState.extra_charge,
            tire_last_status: res.data?.task_results?.tire?.last_status || prevState.tire_last_status,
            disk_last_status: res.data?.task_results?.disk?.last_status || prevState.disk_last_status,
            tire_last_run_time: res.data?.task_results?.tire?.last_run_time || prevState.tire_last_run_time,
            disk_last_run_time: res.data?.task_results?.disk?.last_run_time || prevState.disk_last_run_time,
        }));
        return res;
    }, true)
    const [UpdateImport] = useFetching( async () => {
        const data = {
            "slug": ImportTabs?.slug || '',
            "is_work": ImportTabs?.is_work,
            "extra_charge": ImportTabs?.extra_charge || '',
            "file_id":  ImportTabs?.file_id || '',
        }
        const res = await GetStore.UpdateImport(data)
        // console.log('UpdateImport complete:' , res.data)
        return res;
    }, true)
    const [Import] = useFetching( async (data) => {
        const res = await GetStore.Import(data)
        // console.log('Import complete:' , res.data)
        return res;
    }, true)

    const prevSelectedBtn = useRef(selectedBtn)
    useEffect(() => {
        if(prevSelectedBtn.current !== selectedBtn) {
            if(selectedBtn==='2' || selectedBtn==='3' || selectedBtn==='4' || selectedBtn==='5') {
                setImportTabs({ "file_id": "", "slug": "", "is_work": '', "extra_charge": '', "extra_charge": '', "tire_last_status": "",  "disk_last_status": "", "tire_last_run_time": "", "disk_last_run_time": "",})
                const data = {
                    "slug": `${selectedBtn==='2' ? 'tochki'
                        : selectedBtn==='3' ? 'shininvest'
                        : selectedBtn==='4' ? 'medved' 
                        : selectedBtn==='5' ? 'brineks' 
                        : ''
                    }`
                }
                GetImport(data)
            }
            prevSelectedBtn.current = selectedBtn;
        }
    },[selectedBtn])

    // Получение файла с попапа медиафайлов
    useEffect(() => {
        if (location.state?.confirmFile) {
            // Логика на confirmFile
            if(location.state?.confirmFile?.file_extension === '.xlsx' || location.state?.confirmFile?.file_extension === '.xls') {
                if(location.state?.confirmFile?.id !== null && location.state?.confirmFile?.id !== '') {
                    const fileId = location.state?.confirmFile?.id
                    setImportTabs(prevState => ({
                        ...prevState,
                        file_id: `${fileId}` || prevState.file_id
                    }));
                    if(selectedBtn === '1'){
                        const formdata = new FormData();
                        formdata.append("file", fileId);
                        Import(formdata)
                    }
                }
            }
            // Обнуление состояния confirm в истории
            location.state.confirmFile = undefined
            window.history.replaceState({}, '')
        }
    }, [location.state])

    return (
        <div className="productRed_tabs">
            <div className="productRed_menu base_content_1" style={{flexWrap: 'wrap'}}>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '1' ? 'active' : ''}`} numb={1} onClick={menuBtnClick}>
                    <div className="h-14-500-i">Обычный</div>
                </button>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '2' ? 'active' : ''}`} numb={2} onClick={menuBtnClick}>
                    <div className="h-14-500-i">4 точки</div>
                </button>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '3' ? 'active' : ''}`} numb={3} onClick={menuBtnClick}>
                    <div className="h-14-500-i">Шининвест</div>
                </button>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '4' ? 'active' : ''}`} numb={4} onClick={menuBtnClick}>
                    <div className="h-14-500-i">Медведь</div>
                </button>
                <button style={{minWidth: '200px', maxWidth: 'max-content'}} type="button" className={`productRed_menu_btn ${selectedBtn === '5' ? 'active' : ''}`} numb={5} onClick={menuBtnClick}>
                    <div className="h-14-500-i">Бринэкс</div>
                </button>
            </div>
            {selectedBtn === '1' &&
            <>
            <div className="h-14-400-i">Стандартный способ загрузки. Загрузите excel файл, новые товары будут импортированы, существующие обновлены. </div>
            <div className="productRed_content base_content_1">
                <div className="mediafilesPo_input">
                    <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {file: true, isMulti: false} } })}></button>
                </div>
            </div>
            </>
            }
            {(selectedBtn === '2' || selectedBtn === '3' || selectedBtn === '4' || selectedBtn === '5') &&
            <>
            <div className="productRed_content base_content_1">
                <table>
                    <tbody>
                        <tr>
                            <td className="h-16-400-i">Включить импорт</td>
                            <td><ToggleBtn toggleActive={ImportTabs?.is_work} onChange={(e) => setImportTabs({...ImportTabs, is_work: e})} /></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Коэффициент наценки</td>
                            <td><input name="extra_charge" type="text" className="base-input" value={ImportTabs?.extra_charge || ''} onChange={(e) => setImportTabs({...ImportTabs, extra_charge: e.target.value})}/></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Идентификатор файла</td>
                            <td><div className="h-16-400-i">{ImportTabs?.file_id}</div></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Дата последнего импорта шин</td>
                            <td><div className="h-16-400-i">{ImportTabs?.tire_last_run_time && format(new Date(ImportTabs?.tire_last_run_time), 'dd.MM.yyyy')}</div></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Результат по импорту шин</td>
                            <td><div className="h-16-400-i">{ImportTabs?.tire_last_status}</div></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Дата последнего импорта дисков</td>
                            <td><div className="h-16-400-i">{ImportTabs?.disk_last_run_time && format(new Date(ImportTabs?.disk_last_run_time), 'dd.MM.yyyy')}</div></td>
                        </tr>
                        <tr>
                            <td className="h-16-400-i">Результат по импорту дисков</td>
                            <td><div className="h-16-400-i">{ImportTabs?.disk_last_status}</div></td>
                        </tr>
                    </tbody>
                </table>
                {(selectedBtn === '5') &&
                    <div className="mediafilesPo_input" style={{marginTop: '30px'}}>
                        <button type="button" onClick={() => navigate('/mediafiles_po', { state: { prevLocation: location, mediafilesPodata: {file: true, isMulti: false} } })}></button>
                    </div>
                }
                <div style={{display: 'flex', justifyContent: 'center', paddingTop: '40px'}}>
                    <button onClick={() => UpdateImport()} className="admin_save_btn h-14-500-i white">Сохранить настройки</button>
                </div>
            </div>
            </>
            }
        </div>
    )
}

export default ImportTabs;