import React, { useEffect, useRef, useState } from "react"
import { Link, useLocation, useNavigate } from "react-router-dom";
import img_pack from "../img/img";
import { useFetching } from "../hooks/UseFetching";
import Pagination from "./components/Pagination";
import { AdminArticlesCatRedRootBase, AdminStoreBrandsNewRoot, AdminStoreBrandsRedRootBase, AdminStoreCatNewRoot, AdminStoreCatRedRootBase } from "../utils/consts";
import GetStore from "../API/GetStore";

const AdminStoreBrandsPage = () => {

    const [currentPage, setCurrentPage] = useState(1)
    const [totalPages, setTotalPages] = useState(null)
    const [totalPosts, setTotalPosts] = useState(null)
    const [curSlug, setCurSlug] = useState('');
    const [itemPodata, setItemPodata] = useState({title: 'Бренд будет удален', desc: 'Вы уверены, что хотите удалить бренд безвозвратно?'});
    const [query, setQuery] = useState('');

    const [brandsAll, setBrandsAll] = useState([])
    const location = useLocation()
    const navigate = useNavigate()

    function checkClick(e) {
        if (e.target.classList.contains('active')) {
            e.target.classList.remove('active')
        } else {
            e.target.classList.add('active')
        }
    }

    const [GetStoreBrandsPage, isLoading] = useFetching( async (page) => {
        const formdata = new FormData();
        formdata.append("query", query || ''); //поиск по тексту
        const res = await GetStore.GetStoreBrandsPage(page, formdata)
        setBrandsAll(res.data.data)
        setTotalPages(res.data?.total_pages)
        setTotalPosts(res.data?.total)
        // console.log('GetStoreBrandsPage complete:' , res.data)
    })

    const prevQueryRef = useRef(query);
    useEffect(() => {
        if (prevQueryRef.current !== query) {
            if(currentPage !== 1) {
                setCurrentPage(1)
            } else {
                GetStoreBrandsPage(1)
            }
            prevQueryRef.current = query;
        }
    }, [query]);
    useEffect(() => {
        GetStoreBrandsPage(currentPage)
    },[currentPage])

    // Удаление бренда
    function deleteBrand(slug) {
        setCurSlug(slug)
        navigate('/item_po', { state: { prevLocation: location, itemPodata: itemPodata } })
    }

    const [DeleteStoreBrand] = useFetching( async (slug) => {
        const res = await GetStore.DeleteStoreBrand(slug)
        // console.log('DeleteStoreBrand complete:' , res.data)
        if(currentPage !== 1) {
            setCurrentPage(1)
        } else {
            GetStoreBrandsPage(currentPage)
        }
        return res;
    })

    useEffect(() => {
        if (location.state?.confirmPo) {
            // Обнуление состояния confirm в истории
            location.state.confirmPo = undefined
            window.history.replaceState({}, '')
            // Логика на confirmPo
            DeleteStoreBrand(curSlug)
            setCurSlug('')
        }
    }, [location.state])

    function submit(e) {
        e.preventDefault()
        setQuery(e.currentTarget.elements.search.value)
        // console.log(e.currentTarget.elements.search.value)
    }

    return (
        <div className="admin_content_base">
            <div className="admin_content_header">
                <div className="row-10-a">
                    <div className="h-18-500-i gr8">Бренды</div>
                    <span className="h-12-500-i ba-or">{totalPosts} шт</span>
                </div>
                <div className="row-10-a">
                    <form className={`header-search-form`} onSubmit={submit} style={{display: 'flex', gap: '5px'}}>
                        <input className="base-input" name="search" type="text" placeholder="Найти"/>
                        <button type="submit"><img src={img_pack.search_icon} alt="ic" /></button>
                    </form>
                    <Link to={`${AdminStoreBrandsNewRoot}`} className="create_plus_btn" style={{minWidth: 'max-content'}}>
                        <img src={img_pack.plus_icon} alt="plus_icon" />
                        <span className="h-14-600-i gr7">Создать</span>
                    </Link>
                </div>
            </div>

            <table className="articles_admin_table">
                <thead>
                    <tr>
                        <th>
                            <div>
                                <span className="check_box_base" onClick={checkClick}/>
                                Название
                            </div>
                        </th>
                        <th>
                            <div>Slug бренда</div>
                        </th>
                        <th>
                            <div>Товаров</div>
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {brandsAll?.map((el) => (
                        <tr key={el.id}>
                            <td className="tcats-name">
                                <div>
                                    <span className="check_box_base" onClick={checkClick}/>
                                    <Link to={`${AdminStoreBrandsRedRootBase}${el.slug}`}>
                                        <span className="h-14-500-i gr7">{el.name}</span>
                                    </Link>
                                </div>
                            </td>
                            <td className="t-slug">
                                <div>
                                    <span className="h-14-400-i gr7">{el.slug}</span>
                                </div>
                            </td>
                            <td className="t-cats">
                                <span className="h-12-500-i ba gr7">{el.product_count}</span>
                            </td>
                            <td className="t-icons">
                                <div>
                                    <button onClick={() => deleteBrand(el.slug)}><img src={img_pack.articles_trash_icon} alt="trash_icon" /></button>
                                    <Link to={`${AdminStoreBrandsRedRootBase}${el.slug}`}><img src={img_pack.articles_edit_icon} alt="edit_icon" /></Link>
                                </div>
                            </td>

                        </tr>
                    ))
                    }
                </tbody>
            </table>
            {totalPages > 1 &&
            <div className="t_foot">
                <button onClick={() => {setCurrentPage( ((currentPage - 1) >= 1) ? (currentPage - 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="prev_arrow_svg"/>
                    <span className="h-14-600-i">Предыдущее</span>
                </button>
                <Pagination totalPages={totalPages} pagesToShow={1} currentPage={currentPage} setCurrentPage={setCurrentPage}/>
                <button onClick={() => {setCurrentPage( ((currentPage + 1) <= totalPages) ? (currentPage + 1) : (currentPage) ); window.scrollTo(0, 0);}}>
                    <span className="h-14-600-i">Следующие</span>
                    <span className="next_arrow_svg"/>
                </button>
            </div>
            }
        </div>
    )
}

export default AdminStoreBrandsPage;