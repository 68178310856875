import React, { useEffect, useRef, useState } from "react"

const Product_tabs = ({productTabs, fulldesc}) => {

    const [selectedBtn, setSelectedBtn] = useState('')

    useEffect(() => {
        if(productTabs) {
            if(fulldesc?.blocks?.length > 0) {
                setSelectedBtn('desc')
            } else {
                setSelectedBtn(productTabs[0]?.id)
            }
        }
    },[productTabs])
    
    return (
        <>
        {
        <div className="product_tabs">
            {fulldesc?.blocks?.length > 0 &&
                <button className={`h-14-600-i ${selectedBtn === 'desc' ? 'active' : ''}`} key={'desc'} onClick={() => setSelectedBtn('desc')}>Описание</button>
            }
            {productTabs?.map((el) => (
                <button className={`h-14-600-i ${selectedBtn === el.id ? 'active' : ''}`} key={el.id} onClick={() => setSelectedBtn(el.id)}>{el.name}</button>
            ))}
        </div>}
        {(selectedBtn !== '' && selectedBtn !== undefined) &&
            (selectedBtn === 'desc' ?
                <div className="product-info-block">

                    <div className="col-15">
                        <div className="h-20-400-i">Описание</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="h-14-400-i">
                        {fulldesc?.blocks?.map((el) => (
                            el.type === 'header' ? React.createElement(`h${el.data.level}`, { key: el.id, id: el.id, className: "h-21-600-sf", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : el.type === 'paragraph' ? React.createElement(`p`, { key: el.id, className: "h-16-400-sf c-6", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : <span key={el.id} className="h-16-400-sf c-6" dangerouslySetInnerHTML= {{ __html: (el.data.text || el.data.code) }}></span>
                        ))}
                    </div>

                </div>
                :
                <div className="product-info-block">
    
                    <div className="col-15">
                        <div className="h-20-400-i">{productTabs?.find(obj => obj.id === selectedBtn)?.name}</div>
                        <span style={{borderBottom: '1px solid #D0D5DD'}} />
                    </div>
                    <div className="h-14-400-i">
                        {productTabs?.find(obj => obj.id === selectedBtn)?.value?.blocks?.map((el) => (
                            el.type === 'header' ? React.createElement(`h${el.data.level}`, { key: el.id, id: el.id, className: "h-21-600-sf", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : el.type === 'paragraph' ? React.createElement(`p`, { key: el.id, className: "h-16-400-sf c-6", dangerouslySetInnerHTML: { __html: el.data.text } })
                            : <span key={el.id} className="h-16-400-sf c-6" dangerouslySetInnerHTML= {{ __html: (el.data.text || el.data.code) }}></span>
                        ))}
                    </div>
    
                </div>
            )
        }
        </>
    )
}

export default Product_tabs;