import React, { useContext, useEffect, useRef, useState } from "react"
import img_pack from "../../img/img";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom"
import { Context } from "../../App";
import CustomDpd from "./Custom_dp_link";
import globalFunction, { CartOrderPageRoot, CartPageRoot, Email_verify_Root, ExchangeAndRefundRoot, FaqPageRoot, Login_Root, Password_new_Root, Password_recovery_Root, PaymentAndDeliveryRoot, PrivacyPolicyRoot, Registration_Root, StorePageRoot, breadcrumbMap, egoda_url, splitPathname } from "../../utils/consts";
import BreadCrumb from "./BreadCrumb";
import ProductCounter from "./ProductCounter";
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import HeaderSearch from "./HeaderSearch";

const Header = () => {

    const {windowWidth,ShowCart,UpdateCart,updateQuantity,DeleteFromCart, cart, productsAll, setProductsAll,allBrandsSide, allCatsSide, curModal ,setCurModal} = useContext(Context)
    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [cartIsOpen, setCartIsOpen] = useState(false);
    const [searchIsOpen, setSearchIsOpen] = useState(false);

    const navigate = useNavigate()
    const location = useLocation()

    const [selectedCat, setSelectedCat] = useState({name: '', slug: ''});
    const [selectedBrand, setSelectedBrand] = useState({name: '', slug: ''});

    useEffect(() => {
        if(selectedBrand?.slug !== '') {
            navigate(`${StorePageRoot}`, { state: { initialBrand: selectedBrand } })
        }
        if(selectedCat?.slug !== '') {
            navigate(`${StorePageRoot}`, { state: { initialCat: selectedCat } })
        }
    },[selectedCat?.slug, selectedBrand?.slug])

    function deleteProduct(id) {
        const data = new FormData();
        data.append("product_id", id || '');
        DeleteFromCart(data)
    }

    useEffect(() => {
        if (location.pathname.toLowerCase() === CartPageRoot || location.pathname.toLowerCase() === CartOrderPageRoot) {
            setCartIsOpen(false);
        }
        setMenuIsOpen(false)
        setSearchIsOpen(false)
    }, [location.pathname]); 
    // scroll lock
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    const modalRefs = useRef([]);
    const cartRef = useRef('');
    const addRef = (el) => {
        if (el && !modalRefs.current.includes(el)) {
            modalRefs.current.push(el);
        }
    };
    useEffect(() => {
        const isModalOpen = menuIsOpen || searchIsOpen;
        if (location.pathname.toLowerCase() !== CartPageRoot && location.pathname.toLowerCase() !== CartOrderPageRoot) {
            if (cartIsOpen) {
                document.body.style.paddingRight = `${scrollbarWidth}px`;
                document.body.classList.add('body-no-scroll'); // Отключаем прокрутку
            }
        }
        if (isModalOpen) {
            document.body.style.paddingRight = `${scrollbarWidth}px`;
            document.body.classList.add('body-no-scroll'); // Отключаем прокрутку
        }

        return () => {
            document.body.style.paddingRight = ``;
            document.body.classList.remove('body-no-scroll'); // Убираем класс при размонтировании
        };
    }, [menuIsOpen, cartIsOpen, searchIsOpen, curModal, location.pathname]);

    return (
    <>
        {windowWidth <= 1000 &&
        <div ref={addRef} className={`overlay ${menuIsOpen ? 'open' : ''}`} onClick={() => setMenuIsOpen(false)}>
            <div className={`mobile_menu ${menuIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="mobile_menu_content">
                    <div>
                        <div className="col">
                            <div className="mobile_menu_row_js">
                                <img src={img_pack.assauto_logo} alt="logo" />
                                <button className="close-btn" onClick={() => setMenuIsOpen(false)}><img src={img_pack.close_icon} alt="close_icon" /></button>
                            </div>
                            <Link to={'tel:+73517764991'} className="txtc h-14-400-i">+7 (351) 776-49-91</Link>
                        </div>
                        <div className="col" style={{padding: '10px 16px'}}>
                            <CustomDpd title={'Категории товаров'} className={'h-14-500-i'}>
                                {allCatsSide?.length > 0 && allCatsSide?.map((el) => (
                                    <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedCat?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedCat?.slug === el.slug ? setSelectedCat({name: '', slug: ''}) : setSelectedCat({name: el.name, slug: el.slug})}}>
                                        <span>{el.name}</span>
                                        <span className="ba-total">{el.product_total ? el.product_total : 0}</span>
                                    </button>
                                ))}
                            </CustomDpd>
                            <CustomDpd title={'Бренды'} className={'h-14-500-i'}>
                                {allBrandsSide?.length > 0 && allBrandsSide?.map((el) => (
                                    <button className={`product_choicecat_btn h-14-600-i gr6 ${selectedBrand?.slug === el.slug ? 'active' : ''}`} key={el.id} onClick={() => {selectedBrand?.slug === el.slug ? setSelectedBrand({name: '', slug: ''}) : setSelectedBrand({name: el.name, slug: el.slug})}}>
                                        <span>{el.name}</span>
                                        <span className="ba-total">{el.product_count ? el.product_count : 0}</span>
                                    </button>
                                ))}
                            </CustomDpd>
                            <Link style={{padding: '10px 0'}} to={StorePageRoot} className="h-14-500-i">Магазин</Link>
                            <Link style={{padding: '10px 0'}} to={FaqPageRoot} className="h-14-500-i">Вопросы и ответы</Link>
                            <Link style={{padding: '10px 0'}} to={PaymentAndDeliveryRoot} className="h-14-500-i">Доставка и оплата</Link>
                            <Link style={{padding: '10px 0'}} to={ExchangeAndRefundRoot} className="h-14-500-i">Обмен и возврат</Link>
                            <Link style={{padding: '10px 0'}} to={PrivacyPolicyRoot} className="h-14-500-i">Политика конфиденциальности</Link>
                        </div>
                    </div>
                    <div></div>

                </div>
            </div>
        </div>
        } 
        {(location.pathname.toLowerCase() !== CartPageRoot && location.pathname.toLowerCase() !== CartOrderPageRoot) &&
        <div ref={cartRef} className={`overlay ${cartIsOpen ? 'open' : ''}`} onClick={() => setCartIsOpen(false)}>
            <div className={`cart_menu ${cartIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
                <div className="mobile_menu_content">
                    <div>
                        <div className="mobile_menu_row_js">
                            <div className="row-10-a">
                                <img src={img_pack.cart_icon} alt="cart_icon" />
                                <span className="h-16-600-i">Корзина</span>
                            </div>
                            <button className="close-btn" onClick={() => setCartIsOpen(false)}><img src={img_pack.close_icon} alt="close_icon" /></button>
                        </div>
                        <div className="col">
                            {Object.keys(productsAll)?.length > 0 && Object.values(productsAll)?.map((el) => (
                                <div className="cart_menu_product" key={el.product_id}>
                                    <div>
                                    {el.image_url ?
                                        <img style={{height: '50px', minWidth: '50px', borderRadius: '10px'}} src={`${egoda_url}${el.image_url}`} alt="img" />
                                        : <img style={{height: '50px', width: '50px'}} src={`${img_pack.product_example}`} alt="img" />
                                    }
                                    </div>
                                    <div className="col-10">
                                        <div className="h-14-400-i">{el.product_name}</div>
                                        <div style={{width: 'max-content'}}>
                                            <ProductCounter onChange500={async (e) => await updateQuantity(e,el.product_id) } initialCount={el.quantity} />
                                        </div>
                                        <div className="h-16-400-i"><span className="gr5">{el.quantity} шт.</span> x {globalFunction.withSpaces(globalFunction.split(el.price)[0])} ₽ = {globalFunction.withSpaces(globalFunction.split(el.price * el.quantity)[0])} ₽</div>
                                    </div>
                                    <div>
                                        <button className="cart_menu_del_btn" onClick={() => deleteProduct(el.product_id)}>
                                            <img src={img_pack.close_icon} alt="close_icon" /> 
                                        </button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div>
                        <div className="row-20-js" style={{padding: '20px 0'}}>
                            <div className="h-16-600-i">Сумма:</div>
                            <div className="h-16-600-i">{globalFunction.withSpaces(globalFunction.split(+cart?.total_price)[0])} ₽</div>
                        </div>
                        <div className="col-10">
                            <button className="base-btn white h-14-400-i" onClick={() => {navigate(`${CartPageRoot}`); setCartIsOpen(false)} }>Просмотр корзины</button>
                            <button className="base-btn white h-14-400-i" disabled={Object.keys(productsAll)?.length > 0 ? false : true} onClick={() => {navigate(`${CartOrderPageRoot}`);setCartIsOpen(false)} }>Оформление заказа</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>}

        {windowWidth > 1000 ?
        <header>
            <div className="container">
                <div className="header-main-row">
                    <Link to={'/'} className="header-logo">
                        <img src={`${img_pack.assauto_logo}`} alt="logo" />
                    </Link>
                    <div className="nav-menu h-14-400-j">
                        <Link className="base-btn white h-14-400-j" to={StorePageRoot}>
                            <span>Магазин</span>
                        </Link>
                        <Link className="base-btn white h-14-400-j" to={PaymentAndDeliveryRoot}>
                            <span>Оплата и Доставка</span>
                        </Link>
                        <Link className="base-btn white h-14-400-j" to={ExchangeAndRefundRoot}>
                            <span>Обмен и возврат</span>
                        </Link>
                        {/* <Link to='#!'>
                            <span>Контакты</span>
                        </Link> */}
                        <div className="row-15">
                            <Link to={'https://wa.me/79511134816'}><img src={`${img_pack.contact_whatsappIcon}`} alt="ic" style={{minWidth: '30px', maxWidth: '30px'}}/></Link>
                            <Link to={'https://t.me/TKACC74'}><img src={`${img_pack.contact_telegramIcon}`} alt="ic" style={{minWidth: '30px', maxWidth: '30px'}}/></Link>
                        </div>
                    </div>
                    <div className="row-15-a">
                        <div className="col" style={{alignItems: 'center', textAlign: 'center'}}>
                            <Link className="brand h-20-600-i" to={'tel:+73517764991'}>+7 (351) 776-49-91</Link>
                            <Link className="brand h-12-600-i" to={'mailto:tkass74@internet.ru'}>tkass74@internet.ru</Link>
                            <span className="h-12-400-i gr5">пн-пт: 9:00-18:00, сб-вс: выходной</span>
                        </div>
                        <span style={{borderRight: '1px solid #D0D5DD', width: '1px', height: '29px'}}/>
                        <Link to={'/contact_po'} state={{prevLocation: location}} className="call_btn white h-14-400-j">Заказать звонок</Link>
                    </div>
                </div>
                <div className="header-foot">
                    <div style={{maxWidth: '150px', width: '100%'}}>
                        <button className={`base-btn h-14-400-j white`} onClick={() => navigate(`${StorePageRoot}`)} style={{width: 'max-content'}}>Каталог шин и дисков</button>
                    </div>
                    <HeaderSearch></HeaderSearch>
                    <div style={{minWidth: '155px', display: 'flex', justifyContent: 'flex-end'}}>
                        <button className="header-cart-row" onClick={() => setCartIsOpen(!cartIsOpen)}>
                            <img src={img_pack.cart_icon_wh} alt="cart_icon"/>
                            <div className="h-14-400-i white">{cart?.total_price ? globalFunction.withSpaces(globalFunction.split(cart?.total_price)[0]) : 0} ₽</div>
                        </button>
                    </div>
                </div>
            </div>
        </header>
        :
        <header>
            <div className="container">
                <div className="header-main-row">
                    <button onClick={() => setMenuIsOpen(!menuIsOpen)}>
                        <img src={img_pack.menu_icon} alt="menu_icon" />
                    </button>
                    <Link to={'/'} className="header-logo">
                        <img src={`${img_pack.assauto_logo}`} alt="logo" />
                    </Link>
                    <div className="row-15-a">
                        <button><img src={img_pack.search_icon} alt="search_icon" onClick={() => setSearchIsOpen(true)}/></button>
                        <button><img src={img_pack.cart_icon} alt="cart_icon" onClick={() => setCartIsOpen(true)}/></button>
                    </div>
                </div>
            </div>
        </header>
        }
    <BreadCrumb></BreadCrumb>

    {windowWidth <=1000 &&
    <div ref={addRef} className={`overlay ${searchIsOpen ? 'open' : ''}`} onClick={() => setSearchIsOpen(false)}>
        <div className={`header_search_menu ${searchIsOpen ? 'open' : ''}`} onClick={e => e.stopPropagation()}>
            <div className="mobile_menu_content">
                <HeaderSearch></HeaderSearch>
            </div>
        </div>
    </div>}
    </>
    )
}

export default Header;